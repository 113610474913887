import { Box, Container, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import './App.css';
import Edit from './comp/Edit';
import FinishButtons from './comp/FinishButtons';
import ScoreBox from './comp/ScoreBox';
import TableSelect from './comp/TableSelect';
import Title from './comp/Title';

function App() {

  const defaultStrStore = {
    0: ["K", "C", "R", "H", "N"],
    1: ["Z", "K", "D", "V", "C"],
    2: ["R", "H", "S", "O", "N"],
    3: ["K", "S", "V", "R", "H"],
    4: ["H", "N", "K", "C", "D"],
    5: ["N", "D", "V", "K", "O"],
    6: ["D", "H", "O", "S", "Z"],
    7: ["V", "R", "N", "D", "O"],
    8: ["C", "Z", "H", "K", "S"],
    9: ["O", "R", "Z", "S", "K"]
  }


  let snellenDenominatorForTestDistanceUsed = [200, 160, 100, 80, 63, 50, 40, 32, 25, 20]


  const [dictions, setDictions] = useState({})

  const [strStore, setstrStore] = useState(defaultStrStore)




  // fx to create a copy of strStore with 0 values for diction
  const dictionCopy = (storeCopy) => {
    let dictionCopy = {}
    for (let i = 0; i < 10; i++) {
      dictionCopy[i] = Array(storeCopy[i].length).fill(0)
    }
    // save dictionCopy to dictions
    setDictions(dictionCopy)
  }

  useEffect(() => {
    setstrStore(defaultStrStore);
  }, []);

  useEffect(() => {
    if (strStore && strStore[0].length > 0) {
      dictionCopy(strStore);
    }
  }, [strStore]);

  const [tableSelectVis, setTableSelectVis] = useState(true)
  // console.log("strStore", strStore)


  const reset = () => {
    const newDictions = { ...dictions };
    for (let i = 0; i < 10; i++) {
      newDictions[i] = newDictions[i].map(() => 0);
    }
    setDictions(newDictions);
  };


  return (
    <div>


      <Container maxWidth="sm" >
        <Box sx={{ my: 2 }}>
          <Title />

          <Edit
            setTableSelectVis={setTableSelectVis}
            tableSelectVis={tableSelectVis}
            strStore={strStore}
            setStrStore={setstrStore}
            defaultStrStore={defaultStrStore}
          />

          <Box sx={{ display: tableSelectVis ? "block" : "none" }}>
            <TableSelect dictions={dictions}
              setDictions={setDictions}
              strStore={strStore}
              snellenDenominatorForTestDistanceUsed={snellenDenominatorForTestDistanceUsed} />
            <ScoreBox
              dictions={dictions}
              snellenDenominatorForTestDistanceUsed={snellenDenominatorForTestDistanceUsed} />
          </Box>
          <Box pb={7} sx={{ display: tableSelectVis ? "block" : "none" }}>
            <FinishButtons reset={reset} />

          </Box>
          <Box align="right">
            <Typography variant='body2' color="lightgray">
              v.2.1.0
              {/* change the distance from 20ft to 1.33ft with updated 
              snellen test distance for each level
              Set the default to the correct Chart
              */}
            </Typography>

          </Box>
        </Box>
      </Container>

    </div>
  );
}

export default App;
