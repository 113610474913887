import { Box, Button, Container, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import RemoveIcon from "@mui/icons-material/Remove";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { Fragment } from "react";
import LetterButton from "./LetterButton";
export default function TableSelect(props) {
  const {
    dictions,
    setDictions,
    strStore,
    strStoreState,
    snellenDenominatorForTestDistanceUsed,
  } = props;

  const logMar = (element) => {
    return Math.round(Math.log10(element / 20) * 100) / 100;
  };
  const handleClick = (rowIndex, cellIndex) => {
    const newDictions = { ...dictions };
    newDictions[rowIndex] = [...newDictions[rowIndex]];
    newDictions[rowIndex][cellIndex] =
      newDictions[rowIndex][cellIndex] === 1 ? 0 : 1;
    setDictions(newDictions);
  };

  const handleArrowUpClick = (rowIndex) => {
    const newDictions = { ...dictions };
    for (let row = 0; row < rowIndex; row++) {
      newDictions[row].fill(1);
      setDictions(newDictions);
    }
  };

  const handlePlusClick = (rowIndex) => {
    const newDictions = { ...dictions };
    newDictions[rowIndex].fill(1);
    setDictions(newDictions);

  }

  const handleMinusClick = (rowIndex) => {
    const newDictions = { ...dictions };
    newDictions[rowIndex].fill(0);
    setDictions(newDictions);
  }



  const chunkArray = (array, chunkSize) => {
    return Array.from({ length: Math.ceil(array.length / chunkSize) }, (_, i) =>
      array.slice(i * chunkSize, i * chunkSize + chunkSize)
    );
  };

  return (
    <div>
      <Container sx={{ align: "center", padding: 0 }}>
        <TableContainer sx={{ maxWidth: 400, margin: "auto" }}>
          <Table size="small" aria-label="simple table">
            <TableBody>
              <Box pt={1} display={"flex"} justifyContent={"space-between"}>
                <Typography variant="body2" color="gray">
                  Snellen
                </Typography>
                <Typography variant="body2" color="gray">
                  logMAR
                </Typography>
              </Box>
              {Object.keys(dictions).map((rowIndex, index) => {
                const chunks = chunkArray(dictions[rowIndex], 5); // Split into chunks of 5
                return (
                  <Fragment key={index}>
                    <Box
                      pt={index === 0 ? 0 : 1}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <Box width={40} pt={1}>
                        <Typography variant="body2" color="gray">
                          {snellenDenominatorForTestDistanceUsed[index]}
                        </Typography>
                      </Box>
                      {/* <Box> */}
                      <Button size='small' onClick={() => handleMinusClick(rowIndex)}>
                        <RemoveIcon size="small" sx={{ color: "gray" }} fontSize="small" />
                      </Button>
                      <Button size='small' onClick={() => handleArrowUpClick(rowIndex)}>
                        <KeyboardArrowUpIcon size="small" sx={{ color: "gray" }} fontSize="small" />
                      </Button>
                      <Button size='small' onClick={() => handlePlusClick(rowIndex)}>
                        <AddIcon size="small" sx={{ color: "gray" }} fontSize="small" />
                      </Button>
                      <Box width={40} pt={1}>
                        <Typography variant="body2" color="gray">
                          {logMar(snellenDenominatorForTestDistanceUsed[index])}
                        </Typography>
                      </Box>

                    </Box >
                    {chunks.map((chunk, chunkIndex) => (
                      <TableRow
                        key={chunkIndex}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          padding: 0,
                        }}
                      >
                        <Box
                          align="center"
                          justifyContent="center"
                          display="flex"
                        >
                          {chunk.map((element, cellIndex) => {
                            const realIndex = chunkIndex * 5 + cellIndex; // Calculate the actual index in the row
                            return (
                              <TableCell
                                sx={{
                                  borderBottom: "none",
                                  padding: 0,
                                  paddingY: 0.5,
                                  paddingX: 0.1,
                                }}
                                align="center"
                                justifyContent="space-evenly"
                                key={realIndex}
                              >

                                <LetterButton
                                  handleClick={() =>
                                    handleClick(rowIndex, realIndex)
                                  }
                                  element={element}
                                >
                                  {strStore[rowIndex][realIndex]}
                                </LetterButton>
                              </TableCell>
                            );
                          })}
                        </Box>
                      </TableRow>
                    ))}
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </div>
  );
}
